<script setup>
  const localePath = useLocalePath();
</script>
<template>
  <!-- elérhető méretek: 32 36 40 44 48 -->
  <div id="top" class="xs:mt-32 xs:pt-1 sm:mt-36 md:mt-36 lg:mt-44 xl:mt-40">
    <NavigationCenteredSearch />
    <div class="pt-4 xs:px-1 sm:px-1 md:px-4">
      <slot />
    </div>
    <!-- <Nuxt class="pt-4 xs:px-1 sm:px-1 md:px-4" /> -->
    <Footer />
    <UiBackToTopButton />
    <WebshopModalCart />
    <WebshopCurrencyModal />
    <!-- <AuthForgetPasswordModal /> -->
    <!-- TODO átírni -->
    <!-- <ModalWebshopCurrencyModifiedInfo /> -->
    <!-- <ModalAuthForgetPassword /> -->
    <!-- <CookieBanner /> -->
  </div>
</template>
